import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

import { generateLanguageUrl } from '../util';


function SEO({
  description, meta, title, canonical, alternateLanguagePages,
  alternateLanguageBases
}) {
  const { language, originalPath, defaultLanguage, siteUrl } = useContext(I18nextContext);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const link = [];

  link.push({
    rel: 'canonical',
    href: siteUrl + (canonical || generateLanguageUrl(originalPath, language))
  });

  if (alternateLanguagePages && alternateLanguagePages.edges) {
    alternateLanguagePages = alternateLanguagePages.edges.map(({ node: { url, language: { code } } }) => ({
      path: url,
      language: code
    }));
  }
  let defaultLangPath = undefined;

  if (Array.isArray(alternateLanguagePages)) {
    for (const { path, language: altLanguage } of alternateLanguagePages) {
      if (altLanguage === defaultLanguage) {
        defaultLangPath = path;
      }

      link.push({
        rel: 'alternate',
        href: siteUrl + generateLanguageUrl(path, altLanguage, alternateLanguageBases),
        hrefLang: altLanguage
      });
    }
  }

  if (defaultLangPath !== undefined) {
    link.push({
      rel: 'alternate',
      href: siteUrl + generateLanguageUrl(defaultLangPath, defaultLanguage, alternateLanguageBases),
      hrefLang: 'x-default'
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      link={link}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
