
const { defaultLanguage } = require('../env');


function openFindNeosurf() {
  window.open('https://www.neosurf.com/en_GB/application/findcard?layout=no', 'Neosurf', 'width=600,height=500');
}

const commonHeaderColor = '#a8e5fb';

function urlFromSlug(slug) {
  if (typeof slug === 'string') {
    if (!slug.startsWith('/')) {
      slug = `/${slug}`;
    }

    if (!slug.endsWith('/')) {
      slug = `${slug}/`;
    }
  }

  return slug;
}

function generateLanguageUrl(url, languageCode, alternateLanguageBases) {
  if (alternateLanguageBases) {
    if (Array.isArray(alternateLanguageBases.edges)) {
      alternateLanguageBases = alternateLanguageBases.edges.map(({ node }) => {
        return {
          language: node.language.code,
          url: node.url
        };
      });
    }

    if (Array.isArray(alternateLanguageBases)) {
      const basePage = alternateLanguageBases.find(({ language }) => language === languageCode);

      if (basePage) {
        url = `${basePage.url}/${url}`;
      }
    }
  }

  url = urlFromSlug(url);

  if (languageCode === defaultLanguage || url.startsWith(`/${languageCode}/`)) {
    return url;
  }

  return `/${languageCode}${url}`;
}

module.exports = {
  openFindNeosurf,
  commonHeaderColor,
  urlFromSlug,
  generateLanguageUrl
};